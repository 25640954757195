import '@styles/main.scss';
import HeaderScroller from './components/header-scroller';
import MeetTheTeam from './components/meet-the-team';
import WhatWeDo from './components/what-we-do';
import GalleryLightbox from './components/gallery-lightbox';

class App {
    constructor() {
        new HeaderScroller();
        new MeetTheTeam();
        new WhatWeDo();
        new GalleryLightbox();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
