export default class WhatWeDo {
    constructor() {
        const tabTriggers = document.querySelectorAll('.what-we-do--single-title');
        const tabContent = document.querySelectorAll('.what-we-do--single-content');
        const sectionWrapper = document.getElementById('what-we-do-widget-wrapper');

        if(!sectionWrapper) {
            return;
        }

        const closeAll = () => {
            tabContent.forEach(content => {
                content.classList.remove('is-active');
            });

            tabTriggers.forEach(tab => {
                tab.classList.remove('is-active');
            });
        }

        tabTriggers.forEach(trigger => {
            trigger.addEventListener('click', function() {
                closeAll();

                tabContent.forEach(content => {
                    if (content.id === trigger.id) {
                        content.classList.add('is-active');
                        trigger.classList.add('is-active');
                    }
                });

                const backgroundImage = trigger.getAttribute('data-background-image');
                console.log(backgroundImage)
                if (backgroundImage) {
                    sectionWrapper.style.backgroundImage = `url(${backgroundImage})`;
                }
            });
        });

        // Select the first tab and simulate a click event on page load
        if (tabTriggers.length > 0) {
            tabTriggers[0].classList.add('is-active'); 
            tabContent[0].classList.add('is-active'); 
        
        const firstTabBackgroundImage = tabTriggers[0].getAttribute('data-background-image');
        if (firstTabBackgroundImage) {
            sectionWrapper.style.backgroundImage = `url(${firstTabBackgroundImage})`;
            }
        }
    }
}