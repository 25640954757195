import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default class HeaderScroller {
    constructor() {
        const nav = document.querySelectorAll('.header-navigation');
        const images = document.querySelectorAll('.header-image');
        const dots = document.querySelectorAll('.swiper-pagination');
        console.log(dots)
        let activeItem = '';

        const closeAll = () => {
            nav.forEach(item => {
                item.classList.remove('is-active');
                item.setAttribute('aria-selected', 'false');

                images.forEach(img => {
                    img.classList.remove('is-active');
                    img.setAttribute('aria-selected', 'false');
                })

                dots.forEach(dot => {
                    dot.classList.remove('is-active');
                    dot.setAttribute('aria-selected', 'false');
                })
            })
        }

        nav.forEach(item => {
            item.addEventListener('click', () => {
                closeAll();
                
                item.classList.add('is-active');
                item.setAttribute('aria-selected', 'true');

                activeItem = item.id.replace('header-navigation-', '');

                images.forEach(img => {
                    if(img.classList.contains(`header-image-${activeItem}`)) {
                        img.classList.add('is-active');
                        item.setAttribute('aria-selected', 'true');
                    }
                })

                dots.forEach(dot => {
                    if(dot.classList.contains(`swiper-pagination-${activeItem}`)) {
                        console.log(dot, 'match', activeItem)
                        dot.classList.add('is-active');
                        dot.setAttribute('aria-selected', 'false');
                    }
                })
            })
        })

        const wrapper = document.querySelectorAll('.header--swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const HeaderSwiper = new Swiper('.header-swiper', {

                pagination: {
                    el: '.swiper-pagination',
                    // clickable: true,
                  },

                slidesPerView: 1,
                loop: true,

                autoplay: {
                    delay: 5000,
                },

                modules: [Pagination, Autoplay],
            })
        })

        this.initialSlide();
    }

    initialSlide() {
        const initialSlideNav = document.getElementById('header-navigation-smart-technology');
        const initialSlideImages = document.querySelectorAll('.header-image-smart-technology')
        const initialSlideDots = document.querySelectorAll('.swiper-pagination-smart-technology');

        initialSlideNav?.classList.add('is-active');

        initialSlideImages.forEach(img => {
            img.classList.add('is-active')
        })

        initialSlideDots.forEach(dot => {
            dot.classList.add('is-active');
        })

    }
}