import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default class GalleryLightbox {
    constructor() {

        const tabs = document.querySelectorAll('.what-we-do--single')

        tabs.forEach((tab, index) => {
            Fancybox.bind(`[data-fancybox="gallery-${index + 1}"]`, {
                Carousel: {
                    transition: "slide",
                  },
            });
        });
    }
}